const Trainers = [
  {
    id: 0,
    name: "Akash Kataria",
    type: "Founder",
    instagram: "https://www.instagram.com/akash.katariaa/",
    facebook: "www.facebook.com",
  },
  {
    id: 1,
    name: "Khushboo",
    type: "Nutritionist",
    instagram: "https://www.instagram.com/khushboo.dhamija/",
    facebook: "www.facebook.com",
  },
  {
    id: 2,
    name: "Austin",
    type: "Trainer",
    instagram: "https://www.instagram.com/austinanthony007/",
    facebook: "www.facebook.com",
  },
  {
    id: 3,
    name: "Vyom Gajora",
    type: "Trainer",
    instagram: "https://www.instagram.com/vyom__gajora/",
    facebook: "www.facebook.com",
  },
  {
    id: 4,
    name: "Kshitij",
    type: "Trainer",
    instagram: "https://www.instagram.com/kshitij_barai/",
    facebook: "www.facebook.com",
  },
  {
    id: 5,
    name: "Ron Sharan",
    type: "Trainer",
    instagram: "https://www.instagram.com/ronsharan/",
    facebook: "www.facebook.com",
  },
  {
    id: 6,
    name: "Roshan Malik",
    type: "Trainer",
    instagram: "https://www.instagram.com/roshhh_here__/",
    facebook: "www.facebook.com",
  },
  {
    id: 7,
    name: "Ankush",
    type: "Trainer",
    instagram: "https://www.instagram.com/ankushabhishek_/",
    facebook: "www.facebook.com",
  },
  {
    id: 8,
    name: "Anahita",
    type: "Trainer, Nutritionist",
    instagram: "https://www.instagram.com/anahita.sriprasad/",
    facebook: "www.facebook.com",
  },
  {
    id: 9,
    name: "Haritha",
    type: "Trainer",
    instagram: "https://instagram.com/harrrythaa/",
    facebook: "www.facebook.com",
  },
  {
    id: 10,
    name: "Rishabh",
    type: "Trainer , Nutritionist",
    instagram: "https://www.instagram.com/rishabhdhasmana_coach/",
    facebook: "www.facebook.com",
  },
  {
    id: 11,
    name: "Yashvi Singhvi",
    type: "Nutritionist",
    instagram: "https://www.instagram.com/yashvisinghvi/",
    facebook: "www.facebook.com",
  },
  {
    id: 12,
    name: "Shubham",
    type: "Trainer",
    instagram: "https://www.instagram.com/shubham.1510_/",
    facebook: "www.facebook.com",
  },
  {
    id: 13,
    name: "Aaryan Rawal",
    type: "Brand Strategist",
    instagram: "https://www.instagram.com/aaryanr24/",
    facebook: "www.facebook.com",
  },
  {
    id: 14,
    name: "Anmol Thethi",
    type: "Nutritionist",
    instagram: "https://www.instagram.com/nutritionist.anmolkaur/",
    facebook: "www.facebook.com",
  },
  {
    id: 15,
    name: "Parth Chamaria",
    type: "Trainer",
    instagram: "https://www.instagram.com/parth.chamaria/",
    facebook: "www.facebook.com",
  },
  {
    id: 16,
    name: "Smruti More",
    type: "Trainer",
    instagram: "https://www.instagram.com/smruttiiiiiii/",
    facebook: "www.facebook.com",
  },
  {
    id: 17,
    name: "Sakshi Naik",
    type: "Trainer",
    instagram: "https://www.instagram.com/iamsaaakshiii/",
    facebook: "www.facebook.com",
  },
  {
    id: 18,
    name: "Saakshi Shetty",
    type: "Trainer",
    instagram: "https://www.instagram.com/saakshiishetty/",
    facebook: "www.facebook.com",
  },
  {
    id: 19,
    name: "Saakshi Shelar",
    type: "Trainer, Nutritionist",
    instagram: "https://www.instagram.com/sakshfit/",
    facebook: "www.facebook.com",
  },
  {
    id: 20,
    name: "Akash Sharma",
    type: "Trainer",
    instagram: "https://www.instagram.com/iaksharmaa/",
    facebook: "www.facebook.com",
  },
  {
    id: 21,
    name: "Yash",
    type: "Trainer",
    instagram: "https://www.instagram.com/fit.yashh/",
    facebook: "www.facebook.com",
  },
];
export default Trainers;
